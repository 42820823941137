import { Badge, Button, Table } from 'flowbite-react'
import { useState } from 'react'
import UsersService from '../../../services/users.service'
import Modal from '../../Modal'
import UserForm from './UserForm'
import { toast } from 'react-toastify'
import { HiTrash, HiPencil } from 'react-icons/hi'

const DataTable = ({ data, setUsers }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleEdit = (event, data) => {
    event.preventDefault()
    setModalData(data)
    setShowModal(true)
  }

  const handleDelete = async (event, userId) => {
    event.preventDefault()
    try {
      await UsersService.deleteUser(userId)
      const users = await UsersService.getUsers()
      setUsers(users)
      toast.success('Usuario eliminado correctamente')
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Apellidos</Table.HeadCell>
          <Table.HeadCell>Email</Table.HeadCell>
          <Table.HeadCell>Rol</Table.HeadCell>
          <Table.HeadCell>Estado</Table.HeadCell>
          <Table.HeadCell>
            <span className='sr-only'>Editar</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {data.map((row) => (
            <Table.Row
              key={row.email}
              className='bg-white dark:border-gray-700 dark:bg-gray-800'
            >
              <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                {row.id}
              </Table.Cell>
              <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                {row.name}
              </Table.Cell>
              <Table.Cell>
                {row.firstName} {row.lastName}
              </Table.Cell>
              <Table.Cell>{row.email}</Table.Cell>
              <Table.Cell>{row.roleId === 1 ? 'Admin' : 'User'}</Table.Cell>
              <Table.Cell>
                <div className='flex flex-wrap gap-2'>
                  {row.active ? (
                    <Badge color='success'>Activo</Badge>
                  ) : (
                    <Badge color='failure'>Inactivo</Badge>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className='flex gap-2 justify-end'>
                  <Button
                    color='light'
                    size='xs'
                    onClick={(event) => handleEdit(event, row)}
                  >
                    <HiPencil className='mr-2 h-4 w-4' />
                    Editar
                  </Button>{' '}
                  <Button
                    color='light'
                    size='xs'
                    onClick={(event) => handleDelete(event, row.id)}
                  >
                    <HiTrash className='mr-2 h-4 w-4' />
                    ELiminar
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
          <Modal show={showModal} setShow={setShowModal}>
            <UserForm
              title='Editar usuario'
              data={modalData}
              setShowModal={setShowModal}
              setUsers={setUsers}
              users={data}
            />
          </Modal>
        </Table.Body>
      </Table>
    </>
  )
}

export default DataTable
