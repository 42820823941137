import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const ProtectedRoute = ({ redirectPath = '/', children }) => {
  const { user } = useAuth()

  // console.log(user)

  if (!user) {
    return <Navigate to={redirectPath} replace />
  }

  return children
}

export default ProtectedRoute
