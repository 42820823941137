import { Avatar, Badge, Button, Table } from 'flowbite-react'
import { useState } from 'react'
import EditAgentForm from '../Agents/EditAgentForm'
import { toast } from 'react-toastify'
import { HiTrash, HiPencil } from 'react-icons/hi'
import AgentService from '../../../services/agent.service'

const DataTable = ({ data, setUsers }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleEdit = (event, data) => {
    event.preventDefault()
    setModalData(data)
    setShowModal(true)
  }

  const handleDelete = async (event, userId) => {
    event.preventDefault()
    try {
      await AgentService.deleteAgent(userId)
      const users = await AgentService.getAgents()
      setUsers(users)
      toast.success('Usuario eliminado correctamente')
    } catch (error) {
      alert(error)
    }
  }

  return (
    <>
      <Table>
        <Table.Head>
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Imagen</Table.HeadCell>
          <Table.HeadCell>Nombre</Table.HeadCell>
          <Table.HeadCell>Walcu ID</Table.HeadCell>
          <Table.HeadCell>Estado</Table.HeadCell>
          <Table.HeadCell>
            <span className='sr-only'>Editar</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className='divide-y'>
          {data.map((row) => (
            <Table.Row
              key={row.email}
              className='bg-white dark:border-gray-700 dark:bg-gray-800'
            >
              <Table.Cell className='whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                {row.id}
              </Table.Cell>
              <Table.Cell className='flex whitespace-nowrap font-medium text-gray-900 dark:text-white'>
                <Avatar img={row.imageUrl} rounded={true} />
              </Table.Cell>
              <Table.Cell>{row.name}</Table.Cell>
              <Table.Cell>{row.walcuId}</Table.Cell>
              <Table.Cell>
                <div className='flex flex-wrap gap-2'>
                  {row.active ? (
                    <Badge color='success'>Activo</Badge>
                  ) : (
                    <Badge color='failure'>Inactivo</Badge>
                  )}
                </div>
              </Table.Cell>
              <Table.Cell>
                <div className='flex gap-2 justify-end'>
                  <Button
                    color='light'
                    size='xs'
                    onClick={(event) => handleEdit(event, row)}
                  >
                    <HiPencil className='mr-2 h-4 w-4' />
                    Editar
                  </Button>{' '}
                  <Button
                    color='light'
                    size='xs'
                    onClick={(event) => handleDelete(event, row.id)}
                  >
                    <HiTrash className='mr-2 h-4 w-4' />
                    ELiminar
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
          <EditAgentForm
            show={showModal}
            title='Editar agente'
            data={modalData}
            setShowModal={setShowModal}
            setUsers={setUsers}
            users={data}
          />
        </Table.Body>
      </Table>
    </>
  )
}

export default DataTable
