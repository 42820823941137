import { Alert, Avatar, Button, Label, Modal, TextInput } from 'flowbite-react'
import { useState } from 'react'
import { toast } from 'react-toastify'
import AgentService from '../../../services/agent.service'
import { HiCamera } from 'react-icons/hi'
import axios from 'axios'

const AddUserForm = ({ setUsers, setShowModal, show }) => {
  const [showAlert, setShowAlert] = useState(false)
  const [avatar, setAvatar] = useState({
    src: 'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1625556141/Dashboard-Call-Center/avatar_gomotor_pykwal.jpg',
    default: true,
  })

  const handleUpload = (event) => {
    event.preventDefault()
    if (event.target.files[0]) {
      setAvatar({
        src: URL.createObjectURL(event.target.files[0]),
        alt: event.target.files[0].name,
        file: event.target.files[0],
        default: false,
      })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const newAgent = {}
    data.forEach((value, key) => (newAgent[key] = value))

    try {
      if (!avatar.default) {
        const url = await avatarUpload(avatar.file)
        newAgent.imageUrl = url.data.secure_url
        console.log(url)
        await AgentService.addAgent(newAgent)
      } else {
        newAgent.imageUrl = avatar.src
        await AgentService.addAgent(newAgent)
      }

      const users = await AgentService.getAgents()
      document.getElementById('userForm').reset()
      setUsers(users)
      setAvatar({})
      setShowModal(false)
      toast.success('Agente creado correctamente')
    } catch (error) {
      setShowAlert(true)
    }
  }

  const handleClose = () => {
    document.getElementById('userForm').reset()
    setAvatar({})
    setShowModal(false)
  }

  const avatarUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'call-center')
    const data = await axios.post(
      'https://api.cloudinary.com/v1_1/grup-oliva-motor/upload',
      formData
    )

    return data
  }

  return (
    <Modal show={show} popup={true} size='md' onClose={handleClose}>
      <Modal.Header />
      <Modal.Body>
        <form
          id='userForm'
          className='space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8'
          onSubmit={handleSubmit}
        >
          <div className='relative block'>
            <div className='flex justify-center gap-2'>
              <Avatar
                rounded={true}
                size='xl'
                img={
                  avatar.src ||
                  'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1625556141/Dashboard-Call-Center/avatar_gomotor_pykwal.jpg'
                }
              />
              <div>
                <label for='upload-avatar'>
                  <div className='absolute top-[116px] left-[96px] inline-block p-2 rounded-full cursor-pointer bg-slate-400 hover:bg-slate-200'>
                    <HiCamera />
                  </div>
                </label>
                <input
                  id='upload-avatar'
                  type='file'
                  className='hidden'
                  onChange={handleUpload}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='name' value='Nombre' />
            </div>
            <TextInput id='name' name='name' required={true} />
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='walcuId' value='Walcu ID' />
            </div>
            <TextInput id='walcuId' name='walcuId' required={true} />
          </div>
          {showAlert && (
            <div className='w-full flex gap-4'>
              <Alert color='failure'>
                <span>
                  <span className='font-medium'>Uppss!</span> Ya existe un
                  usuario registrado con este email.
                </span>
              </Alert>
            </div>
          )}
          <div className='w-full flex gap-4 justify-evenly'>
            <Button type='submit'>Guardar cambios</Button>
            <Button color='gray' onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddUserForm
