import AdminLayout from '../layouts/AdminLayout'

const AdminDashboard = () => {
  return (
    <AdminLayout>
      <h1 className='text-3xl'>ssdfsdfdsfsdf</h1>
    </AdminLayout>
  )
}

export default AdminDashboard
