const MainHeader = () => {
  return (
    <div className='table-header'>
      <div
        className='title-card sales-closed'
        style={{ width: '52px', visibility: 'hidden' }}
      >
        <h2 className='text-3xl'>Posición 🤝</h2>
      </div>
      <div className='employed-pic'>
        <h2 style={{ visibility: 'hidden' }}>Usuario:</h2>
      </div>
      <div className='title-card-month calls-made'>
        <h2 className='text-xl'>Total llamadas 📞</h2>
      </div>
      <div className='title-card-month calls-completed'>
        <h2 className='text-xl'>Localizados 📞</h2>
      </div>
      <div className='title-card-month assigning-leads'>
        <h2 className='text-xl'>L. asignados 👍</h2>
      </div>
      <div className='title-card-month managed-sales-leads'>
        <h2 className='text-xl'>Ventas gest. mes 🤝</h2>
      </div>
      <div className='title-card-month sales-closed'>
        <h2 className='text-xl'>Total ventas mes 🤝</h2>
      </div>
    </div>
  )
}

export default MainHeader
