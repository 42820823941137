import { Alert, Button, Label, Select, TextInput } from 'flowbite-react'
import { useState } from 'react'
import UsersService from '../../../services/users.service'
import { toast } from 'react-toastify'

const AddUserForm = ({ setUsers, setShowModal }) => {
  const [showAlert, setShowAlert] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const newUser = {}
    data.forEach((value, key) => (newUser[key] = value))
    newUser.roleId = parseInt(newUser.roleId)
    newUser.active = true

    try {
      console.log(newUser)
      await UsersService.addUser(newUser)
      const users = await UsersService.getUsers()
      setUsers(users)
      setShowModal(false)
      toast.success('Usuario creado correctamente')
    } catch (error) {
      setShowAlert(true)
    }
  }

  const handleClose = () => {
    setShowModal(false)
  }

  return (
    <>
      <form
        id='userForm'
        className='space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8'
        onSubmit={handleSubmit}
      >
        <h3 className='text-xl font-medium text-gray-900 dark:text-white'>
          Añadir nuevo usuario
        </h3>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='name' value='Nombre' />
          </div>
          <TextInput id='email' name='name' required={true} />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='firstName' value='Primer apellido' />
          </div>
          <TextInput id='firstName' name='firstName' required={true} />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='lastName' value='Segundo apellido' />
          </div>
          <TextInput id='lastName' name='lastName' required={true} />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='email' value='Email' />
          </div>
          <TextInput id='email' name='email' required={true} />
        </div>
        <div id='select'>
          <div className='mb-2 block'>
            <Label htmlFor='role' value='Rol' />
          </div>
          <Select id='roleId' name='roleId' required={true}>
            <option value='1'>Admin</option>
            <option value='2'>User</option>
          </Select>
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='password' value='Contraseña' />
          </div>
          <TextInput id='password' name='password' type='password' />
        </div>
        {showAlert && (
          <div className='w-full flex gap-4'>
            <Alert color='failure'>
              <span>
                <span className='font-medium'>Uppss!</span> Ya existe un usuario
                registrado con este email.
              </span>
            </Alert>
          </div>
        )}
        <div className='w-full flex gap-4 justify-evenly'>
          <Button type='submit'>Guardar cambios</Button>
          <Button color='gray' onClick={handleClose}>
            Cancelar
          </Button>
        </div>
      </form>
    </>
  )
}

export default AddUserForm
