import axios from 'axios'

const url = process.env.REACT_APP_HOST_API

const user = JSON.parse(localStorage.getItem('user'))

const getAgents = () => {
  const request = axios.get(url + '/publishing/api/v1/agent', {
    headers: {
      Authorization: 'Bearer ' + user?.token,
    },
  })
  return request.then((response) => response.data)
}

const addAgent = (data) => {
  const request = axios.post(url + '/publishing/api/v1/agent', data)
  return request.then((response) => response.data)
}

const updateAgent = (id, data) => {
  const path = `/publishing/api/v1/agent/${id}`

  console.log(data)
  const request = axios.put(url + path, data)
  return request.then((response) => response.data)
}

const deleteAgent = (id) => {
  const path = `/publishing/api/v1/agent/${id}`

  const request = axios.delete(url + path)
  return request.then((response) => response.data)
}

const AgentService = {
  getAgents,
  addAgent,
  updateAgent,
  deleteAgent,
}

export default AgentService
