/** React Imports */
import { useEffect, useState } from 'react'

/** Components Imports */
import TopHeader from '../components/TopHeader'
import MainHeader from '../components/MainHeader'
import EmployedCard from '../components/EmployedCard'

/** Services Imports */
import WalcuService from '../services/walcu.service'

//** 3party Imports */
import { useTransition, animated } from '@react-spring/web'
import _ from 'lodash'

//** Data Imports*/

import { useAgent } from '../hooks/useAgent'

const CurrentDay = () => {
  const { agents, setAgents } = useAgent()
  const [rows, setRows] = useState([])
  const [positionMedal, setPositionMedal] = useState(false)

  let height = 0

  const transitions = useTransition(
    rows.map((employed) => ({
      ...employed,
      y: (height += 160) - 80,
    })),
    {
      key: (item) => item.name,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    }
  )

  useEffect(() => {
    const fetchData = async () => {
      agents.forEach(async (employed) => {
        const numCallsMade = await WalcuService.getTotalNumberToCallsMadeToday(
          employed.walcuId
        )
        employed.numToCallsMade = numCallsMade
      })
      const employedsOrdering = _.orderBy(agents, ['numToCallsMade'], ['desc'])
      employedsOrdering.forEach((employed, index) => {
        employed.position = index + 1
      })
      setRows(employedsOrdering)
      setPositionMedal(true)
    }

    setInterval(() => {
      fetchData()
    }, 1500)
  }, [agents])

  return (
    <>
      <TopHeader />
      <main>
        <h1 className='text-center text-3xl font-bold'>
          📈 Actividad de Hoy 📈
        </h1>
        <MainHeader />

        {transitions((style, item, t, index, y) => (
          <animated.div
            className=''
            /* style={{ zIndex: agents.length - index, ...style }} */
          >
            <div className='cell'>
              <EmployedCard
                image={item.imageUrl}
                name={item.name}
                userId={item.walcuId}
                position={item.position}
                positionMedal={positionMedal}
              />
            </div>
          </animated.div>
        ))}
        {/*         <EmployedCard
            image={fixedEmployed[0].image}
            name={fixedEmployed[0].name}
            walcu_name={fixedEmployed[0].walcu_name}
            userId={fixedEmployed[0].userId}
            fixed={true}
          /> */}
      </main>
    </>
  )
}

export default CurrentDay
