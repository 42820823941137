import { Sidebar } from 'flowbite-react'
import { HiChartPie, HiUser, HiUsers, HiExternalLink } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

const AdminSidebar = () => {
  const { logout } = useAuth()

  const handleLogout = (event) => {
    event.preventDefault()
    logout()
  }

  return (
    <div className='w-fit h-screen'>
      <Sidebar aria-label='Default sidebar example'>
        <Sidebar.Items className='flex flex-col justify-between h-full'>
          <Sidebar.ItemGroup>
            <a href='/daily'>
              <Sidebar.Item icon={HiChartPie}>Dashboard</Sidebar.Item>
            </a>
            <Link to='/admin/users'>
              <Sidebar.Item icon={HiUser}>Usuarios</Sidebar.Item>
            </Link>
            <Link to='/admin/agents'>
              <Sidebar.Item href='/admin/agents' icon={HiUsers}>
                Agentes
              </Sidebar.Item>
            </Link>
          </Sidebar.ItemGroup>
          <Sidebar.ItemGroup>
            <Sidebar.Item href='#' icon={HiExternalLink} onClick={handleLogout}>
              Cerrar sesión
            </Sidebar.Item>
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  )
}

export default AdminSidebar
