import { Modal as UIModal } from 'flowbite-react'

const Modal = ({ children, show, setShow }) => {
  return (
    <UIModal show={show} size='md' popup={true} onClose={() => setShow(false)}>
      <UIModal.Header />
      <UIModal.Body>{children}</UIModal.Body>
    </UIModal>
  )
}

export default Modal
