import { Avatar, Button, Label, Select, TextInput } from 'flowbite-react'
import { useState } from 'react'
import AgentService from '../../../services/agent.service'
import { HiCamera } from 'react-icons/hi'
import Modal from '../../Modal'
import axios from 'axios'

const EditAgentForm = ({
  users,
  setUsers,
  data,
  title,
  setShowModal,
  show,
}) => {
  const [formData, setFormData] = useState(data)
  const [avatar, setAvatar] = useState({
    src: formData.imageUrl || data.imageUrl,
    default: true,
  })

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleClose = () => {
    document.getElementById('editAgentForm').reset()
    setAvatar({})
    setShowModal(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    data.set('id', parseInt(data.get('id')))
    const newAgent = {}
    data.forEach((value, key) => (newAgent[key] = value))
    newAgent.id = parseInt(newAgent.id)
    newAgent.active = newAgent.active === 'true'
    console.log(newAgent, 'Agent')

    try {
      if (!avatar.default) {
        const url = await avatarUpload(avatar.file)
        newAgent.imageUrl = url.data.secure_url
        console.log(url)
        await AgentService.updateAgent(data.get('id'), newAgent)
      } else {
        await AgentService.updateAgent(data.get('id'), newAgent)
      }
    } catch (error) {}

    const filterUsers = users.filter((user) => user.id !== newAgent.id)
    setUsers([...filterUsers, newAgent].sort((a, b) => a.id - b.id))
    document.getElementById('editAgentForm').reset()
    setAvatar({})
    setShowModal(false)
  }

  const handleUpload = (event) => {
    event.preventDefault()
    if (event.target.files[0]) {
      setAvatar({
        src: URL.createObjectURL(event.target.files[0]),
        alt: event.target.files[0].name,
        file: event.target.files[0],
        default: false,
      })
    }
  }

  const avatarUpload = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'call-center')
    const data = await axios.post(
      'https://api.cloudinary.com/v1_1/grup-oliva-motor/upload',
      formData
    )

    return data
  }

  if (data) {
    return (
      <Modal show={show} setShow={handleClose}>
        <form
          id='editAgentForm'
          className='space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8'
          onSubmit={handleSubmit}
        >
          <div className='relative block'>
            <div className='flex justify-center gap-2'>
              <Avatar
                rounded={true}
                size='xl'
                img={avatar.src || data.imageUrl}
              />
              <div>
                <label for='upload-avatar'>
                  <div className='absolute top-[116px] left-[96px] inline-block p-2 rounded-full cursor-pointer bg-slate-400 hover:bg-slate-200'>
                    <HiCamera />
                  </div>
                </label>
                <input
                  id='upload-avatar'
                  type='file'
                  className='hidden'
                  onChange={handleUpload}
                />
              </div>
            </div>
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='id' value='ID' />
            </div>
            <TextInput id='id' name='id' defaultValue={data.id} readOnly />
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='name' value='Nombre' />
            </div>
            <TextInput
              id='email'
              name='name'
              defaultValue={formData.name || data.name}
              required={true}
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div>
            <div className='mb-2 block'>
              <Label htmlFor='walcuId' value='Walcu ID' />
            </div>
            <TextInput
              id='walcuId'
              name='walcuId'
              defaultValue={formData.walcuId || data.walcuId}
              required={true}
              onChange={(event) => handleChange(event)}
            />
          </div>
          <div className='hidden'>
            <div className='mb-2 block'>
              <Label htmlFor='imageUrl' value='' />
            </div>
            <TextInput
              id='imageUrl'
              name='imageUrl'
              defaultValue={formData.imageUrl || data.imageUrl}
              // onChange={(event) => handleChange(event)}
            />
          </div>
          <div id='state'>
            <div className='mb-2 block'>
              <Label htmlFor='state' value='Estado' />
            </div>
            <Select
              id='active'
              name='active'
              required={true}
              onChange={(event) => handleChange(event)}
            >
              <option selected={data.active === true && true} value='true'>
                Activo
              </option>
              <option selected={data.active === false && true} value='false'>
                Inactivo
              </option>
            </Select>
          </div>
          <div className='w-full flex gap-4'>
            <Button type='submit'>Guardar cambios</Button>
            <Button color='gray' onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default EditAgentForm
