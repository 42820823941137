import axios from 'axios'

const url = process.env.REACT_APP_HOST_API

const login = (data) => {
  const request = axios.post(url + '/publishing/api/v1/auth/login', data)
  return request.then((response) => response.data)
}

const AuthService = {
  login,
}

export default AuthService
