import TopHeader from '../components/TopHeader'
import MainHeaderMonth from '../components/MainHeaderMonth'
import EmployedCardMonth from '../components/EmployedCardMonth'
import { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import _ from 'lodash'
import { useTransition, animated } from '@react-spring/web'
import WalcuService from '../services/walcu.service'
// import employeds from '../utils/employeds'

//** Hooks Imports */
import { useAgent } from '../hooks/useAgent'

const CurrentMonth = () => {
  const { agents, setAgents } = useAgent()
  const [visible, setVisible] = useState('hidden')
  const [showLoading, setShowLoading] = useState(true)
  const [rows, set] = useState([])
  const [positionMedal, setPositionMedal] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false)
      setVisible('visible')
    }, 3000)

    /*     const t = setInterval(() => set(shuffle), 2000)
    return () => clearInterval(t) */

    const fetchData = async () => {
      agents.forEach(async (employed) => {
        const numToSalesClosed =
          await WalcuService.getTotalNumberToSalesClosedPerMonth(
            employed.walcuId
          )
        employed.numToSalesClosed = numToSalesClosed
      })
      const employedsOrdering = _.orderBy(
        agents,
        ['numToSalesClosed'],
        ['desc']
      )
      employedsOrdering.forEach((employed, index) => {
        employed.position = index + 1
        console.log(employed)
      })
      set(employedsOrdering)
      setPositionMedal(true)
    }

    setInterval(() => {
      fetchData()
    }, 1500)
  }, [agents])

  let height = 0

  const transitions = useTransition(
    rows.map((employeds) => ({
      ...employeds,
      y: (height += 160) - 80,
    })),
    {
      key: (item) => item.name,
      from: { height: 0, opacity: 0 },
      leave: { height: 0, opacity: 0 },
      enter: ({ y, height }) => ({ y, height, opacity: 1 }),
      update: ({ y, height }) => ({ y, height }),
    }
  )

  return (
    <div>
      <TopHeader />
      <main>
        <h1 className='text-center text-3xl font-bold'>📈 Mes actual 📈</h1>
        {showLoading ? <Loading /> : ''}
        <div style={{ visibility: visible }}>
          <MainHeaderMonth />
          {transitions((style, item, t, index, y) => (
            <animated.div
              className=''
              /* style={{ zIndex: agents.length - index, ...style }} */
            >
              <div className='cell'>
                <EmployedCardMonth
                  image={item.imageUrl}
                  name={item.name}
                  userId={item.walcuId}
                  position={item.position}
                  positionMedal={positionMedal}
                />
              </div>
            </animated.div>
          ))}
          {/*           <EmployedCardMonth
            image={fixedEmployed[0].image}
            name={fixedEmployed[0].name}
            walcu_name={fixedEmployed[0].walcu_name}
            userId={fixedEmployed[0].userId}
            fixed={true}
          /> */}
        </div>
      </main>
    </div>
  )
}

export default CurrentMonth
