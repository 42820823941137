import { Button } from 'flowbite-react'
import { Link } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import LogoutButton from './LogoutButton'

const TopHeader = () => {
  const { user } = useAuth()

  return (
    <header className='Top-header'>
      <div className='flex items-center'>
        <div>
          <img
            src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1624441311/Multipublicador%20GrupOlivaMotor/logo_xv7uea.png'
            alt='Logo GrupOlivaMotor'
          />
        </div>
      </div>
      <div className='mr-4 flex gap-4'>
        {user.roleId === 1 && (
          <Link to='/admin/agents'>
            <Button color='dark' size='xs'>
              Admin Dashboard
            </Button>
          </Link>
        )}
        <LogoutButton />
      </div>
    </header>
  )
}

export default TopHeader
