import { Alert, Button } from 'flowbite-react'
import { useEffect, useState } from 'react'
import AddUserForm from '../components/Admin/Users/AddUserForm'
import DataTable from '../components/Admin/Users/DataTableUsers'
import Modal from '../components/Modal'
import Toast from '../components/Toast'
import AdminLayout from '../layouts/AdminLayout'
import { HiUserAdd } from 'react-icons/hi'
import UsersService from '../services/users.service'

const AdminUsers = () => {
  const [users, setUsers] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')

  useEffect(() => {
    let mounted = true

    ;(async () => {
      if (mounted) {
        try {
          const data = await UsersService.getUsers()
          setUsers(data)
        } catch (error) {
          setResponseMessage(error.message)
          setShowAlert(true)
        }
      }
    })()

    return () => (mounted = false)
  }, [])

  // console.log(users, 'cargado')

  return (
    <AdminLayout>
      <section className='w-full p-4'>
        <h1 className='text-2xl'>Lista de usuarios</h1>
        <div className='flex justify-end mb-4'>
          <Button size='xs' onClick={() => setShowModal(true)}>
            <HiUserAdd className='mr-2 h-4 w-4' />
            Nuevo usuario
          </Button>
        </div>
        {users.length > 0 && <DataTable data={users} setUsers={setUsers} />}
        <Modal show={showModal} setShow={setShowModal}>
          <AddUserForm
            users={users}
            setUsers={setUsers}
            setShowModal={setShowModal}
          />
        </Modal>
        {showAlert && (
          <div className='w-full flex gap-4'>
            <Alert color='failure'>
              <span>
                <span className='font-medium'>Uppss!</span> Ha ocurrido un
                error: {responseMessage}
              </span>
            </Alert>
          </div>
        )}
      </section>
      <Toast />
    </AdminLayout>
  )
}

export default AdminUsers
