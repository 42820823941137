const Loading = () => {
  return (
    <ul class='loading'>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  )
}

export default Loading
