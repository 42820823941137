import { createContext } from 'react'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from '../hooks/useLocalStorage'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const navigate = useNavigate()

  const login = async (data) => {
    // console.log(data)
    if (!data.active) {
      setUser(null)
      navigate('/', { replace: true })
    } else {
      setUser(data)
      navigate('/daily')
    }
  }

  const logout = async () => {
    setUser(null)
    navigate('/', { replace: true })
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
