import './App.css'

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom"

import { AuthContextProvider } from './context/authContext'
import { AgentContextProvider } from './context/agentContext'

import Admin from './pages/AdminDashboard'
import Login from './pages/Login'
import CurrentDay from './pages/CurrentDay'
import CurrentMonth from './pages/CurrentMonth'
import NotFound from './pages/NotFound'
import ProtectedRoute from './components/ProtectedRoute'
import AdminUsers from './pages/AdminUsers'
import AdminAgent from './pages/AdminAgent'

function App() {
  return (
    <BrowserRouter>
      <AuthContextProvider>
        <AgentContextProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/admin"
                element={
                  <ProtectedRoute>
                    <Admin />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/users"
                element={
                  <ProtectedRoute>
                    <AdminUsers />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/admin/agents"
                element={
                  <ProtectedRoute>
                    <AdminAgent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/daily"
                element={
                  <ProtectedRoute>
                    <CurrentDay />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/monthly"
                element={
                  <ProtectedRoute>
                    <CurrentMonth />
                  </ProtectedRoute>
                }
              />
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </div>
        </AgentContextProvider>
      </AuthContextProvider>
    </BrowserRouter>
  )
}

export default App;
