import axios from 'axios'
import DateUtils from '../utils/DateUtils'
import employeds from '../utils/employeds'

const firstDayOfMonth = DateUtils.getFirstDayOfMonth()
const lastDayOfMonth = DateUtils.getLastDayOfMonth()
const firstDateDayOfMonth = DateUtils.getDateFirstDayOfMonth()
const lastDateDayOfMonth = DateUtils.getDateLastDayOfMonth()

const credentials = {
  appId: process.env.REACT_APP_APP_ID,
  dealer: process.env.REACT_APP_DEALER_ID,
  apiKey: process.env.REACT_APP_API_KEY,
}

const auth = {
  username: credentials.appId,
  password: credentials.apiKey,
}

const headers = {
  'x-timezone': 'Europe/Madrid',
}

const walcuUrl = `https://api.crm.walcu.com/dealers/${credentials.dealer}`
const url = process.env.REACT_APP_HOST_API

const walcuIds = employeds.map((e) => e.userId)

const getTotalNumberToCallsMadeToday = async (walcu_name) => {
  const query = `made_at = $today AND direction != "internal" AND users = "${walcu_name}" AND users != ${walcuIds
    .filter((e) => e !== walcu_name)
    .toString()} &group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/conferences?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    // console.log(num.data[0].value)
    return num.data[0].value
  }
}

const getTotalNumberToCallsMadePerMonth = async (walcu_name) => {
  const query = `made_at >= "${firstDayOfMonth}" AND made_at <= "${lastDayOfMonth}" AND direction != "internal" AND users = "${walcu_name}" AND users != ${walcuIds
    .filter((e) => e !== walcu_name)
    .toString()}&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/conferences?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToCallsCompletedToday = async (walcu_name) => {
  const query = `made_at = $today AND direction != "internal" AND users = "${walcu_name}" AND status ~ completed AND duration >= 15&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/conferences?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToCallsCompletedPerMonth = async (walcu_name) => {
  const query = `made_at >= "${firstDayOfMonth}" AND made_at <= "${lastDayOfMonth}" AND direction != internal AND users = "${walcu_name}" AND status ~ completed AND duration >= 15&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/conferences?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToAssignedLeadsToday = async (userId) => {
  const query = `assigned_by = ${userId} AND assigned_at = $today AND assigned_to != 62d688d0d737f9acb963e7d4,622f6c190e542ffbbd4d4110,627b6d23f05b923bb0f90791,60bf17cf383f0c003a6a1634,5ec7e2876eb9b300040811fc,60bf17a08829ba003adab873,62e0e7584d83321639a01670&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToAssignedLeadsPerMonth = async (userId) => {
  const query = `(assigned_by = ${userId} AND (assigned_at >= ${firstDateDayOfMonth} AND assigned_at <= ${lastDateDayOfMonth}) AND assigned_to != 62d688d0d737f9acb963e7d4,622f6c190e542ffbbd4d4110,627b6d23f05b923bb0f90791,60bf17cf383f0c003a6a1634,5ec7e2876eb9b300040811fc,60bf17a08829ba003adab873,62e0e7584d83321639a01670)&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToSalesLeadsManagedToday = async (userId) => {
  const query = `assigned_by = ${userId} AND assigned_at = $today AND current_status = 5e67ca2050887200040811bd&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToSalesManagedPerMonth = async (userId) => {
  const query = `( assigned_by = ${userId} (assigned_at >= ${firstDateDayOfMonth} AND assigned_at <= ${lastDateDayOfMonth}) AND current_status = 5e67ca2050887200040811bd )&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToSalesClosedToday = async (userId) => {
  const query = `assigned_by = ${userId} AND current_status = 5e67ca2050887200040811bd AND current_status_date = $today&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const getTotalNumberToSalesClosedPerMonth = async (userId) => {
  const query = `( assigned_by = ${userId} AND current_status = 5e67ca2050887200040811bd AND current_status_date >= ${firstDateDayOfMonth} AND assigned_at <= ${lastDateDayOfMonth})&group_by={"_id":"$dealer_id","value":{"$sum":1}}`
  const num = await axios.get(url + '/saleleads?search=' + query, {
    headers,
    auth,
  })
  if (num.data.length === 0) {
    return 0
  } else {
    return num.data[0].value
  }
}

const WalcuService = {
  getTotalNumberToCallsMadeToday,
  getTotalNumberToCallsMadePerMonth,
  getTotalNumberToCallsCompletedToday,
  getTotalNumberToCallsCompletedPerMonth,
  getTotalNumberToAssignedLeadsToday,
  getTotalNumberToAssignedLeadsPerMonth,
  getTotalNumberToSalesLeadsManagedToday,
  getTotalNumberToSalesManagedPerMonth,
  getTotalNumberToSalesClosedToday,
  getTotalNumberToSalesClosedPerMonth,
}

export default WalcuService
