import { useEffect, useState, useRef } from 'react'
import WalcuService from '../services/walcu.service'
import AnimatedNumber from 'animated-number-react'
import UIFx from 'uifx'
import mp3File from '../sounds/bells.mp3'
import mp3Fail from '../sounds/fail.mp3'

const bells = new UIFx(mp3File, {
  volume: 1.0, // number between 0.0 ~ 1.0
  throttleMs: 100,
})

const failSound = new UIFx(mp3Fail, {
  volume: 1.0,
  throttleMs: 100,
})

const EmployedCard = ({ image, name, walcu_name, userId, fixed, position }) => {
  const [callsMade, setCallsMade] = useState()
  const [callsMadeAnimated, setCallsMadeAnimated] = useState(false)

  const [callsCompletedPercent, setCallsCompletedPercent] = useState()
  const [callsCompletedPercentAnimated, setCallsCompletedPercentAnimated] =
    useState(false)
  const [
    callsCompletedPercentAnimatedDown,
    setCallsCompletedPercentAnimatedDown,
  ] = useState(false)

  const [leadsAssigned, setLeadsAssigned] = useState()
  const [leadsAssignedAnimated, setLeadsAssignedAnimated] = useState(false)
  const [leadsAssignedAnimatedDown, setLeadsAssignedAnimatedDown] =
    useState(false)

  const [leadsManagedToday, setLeadsManagedToday] = useState()
  const [leadsManagedTodayAnimated, setLeadsManagedTodayAnimated] =
    useState(false)
  const [leadsManagedTodayAnimatedDown, setLeadsManagedTodayAnimatedDown] =
    useState(false)

  const [salesClosed, setSalesClosed] = useState()
  const [salesClosedAnimated, setSalesClosedAnimated] = useState(false)
  const [salesClosedAnimatedDown, setSalesClosedAnimatedDown] = useState(false)

  const [numberIsLoad, setNumberIsLoad] = useState(false)

  const usePrevious = (value) => {
    const ref = useRef()
    useEffect(() => {
      ref.current = value
    }, [value])
    return ref.current
  }

  const prevCountPercent = usePrevious(callsCompletedPercent)
  const prevLeadsAssigned = usePrevious(leadsAssigned)
  const prevLeadsManaged = usePrevious(leadsManagedToday)
  const prevSalesClosed = usePrevious(salesClosed)

  useEffect(() => {
    const fetchData = async () => {
      const numCallsMade = await WalcuService.getTotalNumberToCallsMadePerMonth(
        userId
      )
      const numCallsCompleted =
        await WalcuService.getTotalNumberToCallsCompletedPerMonth(userId)
      const callsCompletedPercent = (numCallsCompleted / numCallsMade) * 100
      const numToAssignedLeads =
        await WalcuService.getTotalNumberToAssignedLeadsPerMonth(userId)

      const numToLeadsManagedToday =
        await WalcuService.getTotalNumberToSalesManagedPerMonth(userId)
      const numToSalesClosed =
        await WalcuService.getTotalNumberToSalesClosedPerMonth(userId)

      if (callsCompletedPercent === 100) {
        setCallsCompletedPercent(callsCompletedPercent.toFixed(0))
      } else {
        setCallsCompletedPercent(callsCompletedPercent.toFixed(2))
      }
      setCallsMade(numCallsMade)
      setLeadsAssigned(numToAssignedLeads)
      setLeadsManagedToday(numToLeadsManagedToday)
      setSalesClosed(numToSalesClosed)
      setNumberIsLoad(true)
    }

    fetchData()

    setTimeout(() => {
      window.location.href = '/daily'
    }, 30000)
  }, [walcu_name, userId])

  const formatNum = (num) => (num === 100 ? num.toFixed(0) : num.toFixed(2))

  const handleChangeCallsMade = (value) => {
    if (numberIsLoad) {
      console.log(value)
      setCallsMadeAnimated(true)
      setTimeout(() => {
        setCallsMadeAnimated(false)
      }, 5000)
    }
  }

  const handleChangeCallsCompletedPercent = (value) => {
    if (numberIsLoad) {
      console.log(value)
      console.log(prevCountPercent)
      if (value < prevCountPercent) {
        setCallsCompletedPercentAnimatedDown(true)
      } else {
        setCallsCompletedPercentAnimated(true)
      }
      setTimeout(() => {
        setCallsCompletedPercentAnimated(false)
        setCallsCompletedPercentAnimatedDown(false)
      }, 5000)
    }
  }

  const handleChangeLeadsAssigned = (value) => {
    if (numberIsLoad) {
      console.log(value)
      console.log(prevLeadsAssigned)
      if (value < prevLeadsAssigned) {
        setLeadsAssignedAnimatedDown(true)
      } else {
        setLeadsAssignedAnimated(true)
      }
      setTimeout(() => {
        setLeadsAssignedAnimated(false)
        setLeadsAssignedAnimatedDown(false)
      }, 5000)
    }
  }

  const handleChangeLeadsManagedToday = (value) => {
    if (numberIsLoad) {
      console.log(value)
      console.log(prevLeadsManaged)
      if (value < prevLeadsManaged) {
        setLeadsManagedTodayAnimatedDown(true)
      } else {
        setLeadsManagedTodayAnimated(true)
      }
      setTimeout(() => {
        setLeadsManagedTodayAnimated(false)
        setLeadsManagedTodayAnimatedDown(false)
      }, 5000)
    }
  }

  const handleChangeSalesClosed = (value) => {
    if (numberIsLoad) {
      console.log(value)
      console.log(prevSalesClosed)
      if (value < prevSalesClosed) {
        setSalesClosedAnimatedDown(true)
        failSound.setVolume(1.0).play()
      } else {
        // bells.setVolume(0.8).play()
        setSalesClosedAnimated(true)
      }
      setTimeout(() => {
        setSalesClosedAnimated(false)
        setSalesClosedAnimatedDown(false)
      }, 5000)
    }
  }

  return (
    <div className={fixed ? 'employed-card fixed' : 'employed-card'}>
      <div className='sub-card-medal'>
        <p className='big-number'>
          {position === 1
            ? '🥇'
            : '' || position === 2
            ? '🥈'
            : '' || position === 3
            ? '🥉'
            : ''}
        </p>
        {/*         { fixed ? (
          <p className='big-number icon-hidden'>📈</p>
        ) : ''} */}
      </div>
      <div className='employed-pic'>
        <span>{name}</span>
        <img src={image} alt={name} />
      </div>
      <div className='sub-card-month calls-made'>
        <p className='big-number'>
          <AnimatedNumber
            value={callsMade}
            formatValue={(value) => value.toFixed(0)}
            className={callsMadeAnimated ? 'zoom-success' : ''}
            complete={() => handleChangeCallsMade(callsMade)}
          />
        </p>
      </div>
      <div className='sub-card-month calls-completed'>
        <p className='big-number'>
          <span
            className={
              callsCompletedPercentAnimated
                ? 'zoom-success'
                : '' || callsCompletedPercentAnimatedDown
                ? 'zoom-loser'
                : ''
            }
          >
            <AnimatedNumber
              value={callsCompletedPercent}
              formatValue={(value) => formatNum(value)}
              complete={() =>
                handleChangeCallsCompletedPercent(callsCompletedPercent)
              }
            />{' '}
            %
          </span>
        </p>
      </div>
      <div className='sub-card-month assigning-leads'>
        <p className='big-number'>
          <AnimatedNumber
            value={leadsAssigned}
            formatValue={(value) => value.toFixed(0)}
            className={
              leadsAssignedAnimated
                ? 'zoom-success'
                : '' || leadsAssignedAnimatedDown
                ? 'zoom-loser'
                : ''
            }
            complete={() => handleChangeLeadsAssigned(leadsAssigned)}
          />
        </p>
      </div>
      <div className='sub-card-month managed-sales-leads'>
        <p className='big-number'>
          <AnimatedNumber
            value={leadsManagedToday}
            formatValue={(value) => value.toFixed(0)}
            className={
              leadsManagedTodayAnimated
                ? 'zoom-success'
                : '' || leadsManagedTodayAnimatedDown
                ? 'zoom-loser'
                : ''
            }
            complete={() => handleChangeLeadsManagedToday(leadsManagedToday)}
          />
        </p>
      </div>
      <div className='sub-card-month sales-closed'>
        <p className='big-number' style={{ fontWeight: '700' }}>
          <AnimatedNumber
            value={salesClosed}
            formatValue={(value) => value.toFixed(0)}
            className={
              salesClosedAnimated
                ? 'zoom-success'
                : '' || salesClosedAnimatedDown
                ? 'zoom-loser'
                : ''
            }
            complete={() => handleChangeSalesClosed(salesClosed)}
          />
        </p>
      </div>
    </div>
  )
}

export default EmployedCard
