import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Toast = () => {
  return (
    <ToastContainer
      position='bottom-right'
      autoClose={false}
      className='text-sm'
      style={{
        fontFamily: 'Segoe UI, sans-serif',
      }}
    />
  )
}

export default Toast
