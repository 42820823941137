import { createContext, useEffect, useState } from 'react'
import AgentService from '../services/agent.service'

export const AgentContext = createContext()

export const AgentContextProvider = ({ children }) => {
  const [agents, setAgents] = useState([])

  useEffect(() => {
    if (agents.length === 0) {
      AgentService.getAgents()
        .then((data) => {
          data.filter((e) => e.active !== false)
          setAgents(data.filter((e) => e.active !== false))
        })
        .catch((error) => console.error(error))
    }
  })

  return (
    <AgentContext.Provider value={{ agents, setAgents }}>
      {children}
    </AgentContext.Provider>
  )
}
