const date = new Date()

const getFirstDayOfMonth = () => {
  const fisrtDay = new Date(date.getFullYear(), date.getMonth(), 1)
  return fisrtDay.toISOString()
}

const getLastDayOfMonth = () => {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDay.toISOString()
}

const getDateLastDayOfMonth = () => {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return lastDay.toLocaleDateString("fr-CA")
}

const getDateFirstDayOfMonth = () => {
  const lastDay = new Date(date.getFullYear(), date.getMonth(), 1)
  return lastDay.toLocaleDateString("fr-CA")
}

const DateUtils = {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getDateFirstDayOfMonth,
  getDateLastDayOfMonth,
}

export default DateUtils
