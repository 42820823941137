import { Button, Label, Select, TextInput } from 'flowbite-react'
import { useState } from 'react'
import UsersService from '../../../services/users.service'

const UpdateUserForm = ({ users, setUsers, data, title, setShowModal }) => {
  const [formData, setFormData] = useState(data)

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleClose = () => {
    document.getElementById('userForm').reset()
    setShowModal(false)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    data.set('id', parseInt(data.get('id')))
    const newUser = {}
    data.forEach((value, key) => (newUser[key] = value))
    newUser.id = parseInt(newUser.id)
    newUser.roleId = parseInt(newUser.roleId)
    newUser.active = newUser.active === 'true'

    console.log(newUser)

    await UsersService.updateUser(data.get('id'), newUser)

    const filterUsers = users.filter((user) => user.email !== newUser.email)
    setUsers([...filterUsers, newUser].sort((a, b) => a.id - b.id))
    setShowModal(false)
  }

  if (data) {
    return (
      <form
        id='userForm'
        className='space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8'
        onSubmit={handleSubmit}
      >
        <h3 className='text-xl font-medium text-gray-900 dark:text-white'>
          {title}
        </h3>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='id' value='ID' />
          </div>
          <TextInput id='id' name='id' defaultValue={data.id} readOnly />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='name' value='Nombre' />
          </div>
          <TextInput
            id='email'
            name='name'
            defaultValue={formData.name || data.name}
            required={true}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='firstName' value='Primer apellido' />
          </div>
          <TextInput
            id='firstName'
            name='firstName'
            defaultValue={formData.firstName || data.firstName}
            required={true}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='lastName' value='Segundo apellido' />
          </div>
          <TextInput
            id='lastName'
            name='lastName'
            defaultValue={formData.lastName || data.lastName}
            required={true}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='email' value='Email' />
          </div>
          <TextInput
            id='email'
            name='email'
            defaultValue={formData.email || data.email}
            required={true}
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div id='select'>
          <div className='mb-2 block'>
            <Label htmlFor='roleId' value='Rol' />
          </div>
          <Select
            id='roleId'
            name='roleId'
            required={true}
            onChange={(event) => handleChange(event)}
          >
            <option value='1' selected={data.roleId === 1 ? 1 : 2}>
              Admin
            </option>
            <option value='2' selected={data.roleId === 2 ? 1 : 2}>
              User
            </option>
          </Select>
        </div>
        <div id='state'>
          <div className='mb-2 block'>
            <Label htmlFor='state' value='Estado' />
          </div>
          <Select
            id='active'
            name='active'
            required={true}
            onChange={(event) => handleChange(event)}
          >
            <option selected={data.active === true && true} value='true'>
              Activo
            </option>
            <option selected={data.active === false && true} value='false'>
              Inactivo
            </option>
          </Select>
        </div>
        <div>
          <div className='mb-2 block'>
            <Label htmlFor='password' value='Contraseña' />
          </div>
          <TextInput
            id='password'
            name='password'
            type='password'
            onChange={(event) => handleChange(event)}
          />
        </div>
        <div className='w-full flex gap-4'>
          <Button type='submit'>Guardar cambios</Button>
          <Button color='gray' onClick={handleClose}>
            Cancelar
          </Button>
        </div>
      </form>
    )
  }
}

export default UpdateUserForm
