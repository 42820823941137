import AdminSidebar from '../components/Sidebar'

const AdminLayout = ({ children }) => {
  return (
    <section className='flex'>
      <AdminSidebar />
      {children}
    </section>
  )
}

export default AdminLayout
