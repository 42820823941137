const employeds = [
  {
    name: 'Francesc Gispert',
    image:
      'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1625753790/Dashboard-Call-Center/francesc_gispert_zxmzxu.jpg',
    userId: '5ec7e2876eb9b300040811fc',
    walcu_name: 'francesc gispert',
    height: 80,
  },
  {
    name: 'Alicia Soriano',
    image:
      'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1625753778/Dashboard-Call-Center/alicia_soriano_i5dlfr.jpg',
    userId: '60bf17a08829ba003adab873',
    walcu_name: 'alicia soriano',
    height: 80,
  },
  {
    name: 'Pere Margallo',
    image:
      'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1665385968/Dashboard-Call-Center/pere_margallo_h8poru.jpg',
    userId: '62e0e7584d83321639a01670',
    walcu_name: 'pere margallo',
    height: 80,
  },
  {
    name: 'Erika Sedeño',
    image:
      'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1665386359/Dashboard-Call-Center/erika_sede%C3%B1o_omaknh.jpg',
    userId: '6328336694595a2e816add12',
    walcu_name: 'erika sedeño',
    height: 80,
  },
  {
    name: 'Daniel Méndez',
    image:
      'https://res.cloudinary.com/grup-oliva-motor/image/upload/v1670250996/Dashboard-Call-Center/dani_mendez_glzm1d.jpg',
    userId: '636ce438d11b7ed9a65c01fb',
    walcu_name: 'daniel mendez',
    height: 80,
  },
]

export default employeds
