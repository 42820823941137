const MainHeader = () => {
  return (
    <div className='table-header'>
      <div
        className='title-card sales-closed'
        style={{ width: '52px', visibility: 'hidden' }}
      >
        <h2 className='text-3xl'>Posición 🤝</h2>
      </div>
      <div className='employed-pic'>
        <h2 style={{ visibility: 'hidden' }}>Usuario :</h2>
      </div>
      <div className='title-card calls-made'>
        <h2 className='text-xl'>Total llamadas 📞</h2>
      </div>
      <div className='text-xl title-card calls-completed'>
        <h2>Localizados 📞</h2>
      </div>
      <div className='text-xl title-card assigning-leads'>
        <h2>L. asignados 👍</h2>
      </div>
      <div className='text-xl title-card managed-sales-leads'>
        <h2>Ventas gest. hoy 🤝</h2>
      </div>
      <div className='text-xl title-card sales-closed'>
        <h2>Total ventas hoy 🤝</h2>
      </div>
    </div>
  )
}

export default MainHeader
