import { useState } from 'react'
import { useAuth } from '../hooks/useAuth'

import { Alert, Button, Footer, TextInput } from 'flowbite-react'
import AuthService from '../services/auth.service'

const Login = () => {
  const [showAlert, setShowAlert] = useState(false)
  const { login } = useAuth()

  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)

    try {
      const auth = await AuthService.login({
        email: data.get('email'),
        password: data.get('password'),
      })

      const jwt = auth.data

      const user = JSON.parse(atob(jwt.split('.')[1]))
      user.token = jwt
      login(user)
    } catch (error) {
      console.log(error)
      setShowAlert(true)
    }
  }

  return (
    <main style={{ padding: '0', margin: '0' }}>
      <section className='animate-fade-in'>
        <div className='grid place-items-center w-screen h-screen text-center'>
          <form onSubmit={handleSubmit} className='flex flex-col gap-4 w-80'>
            <img
              src='https://res.cloudinary.com/grup-oliva-motor/image/upload/v1625823108/Dashboard-Call-Center/LogosMarcas/logo-grup-oliva-1_hgknz0.png'
              alt='GrupOlivaMotor'
            />
            <h1 className='mb-8 text-3xl font-semibold'>
              Dashboard Call Center
            </h1>
            <TextInput
              id='email'
              name='email'
              type='email'
              placeholder='correo electrónico'
              required={true}
            />
            <TextInput
              id='password'
              name='password'
              type='password'
              placeholder='contraseña'
              required={true}
            />
            <Button type='submit' size='md' color='dark'>
              Iniciar sesión
            </Button>
            {showAlert && (
              <Alert
                color='failure'
                onDismiss={function onDismiss() {
                  return setShowAlert(false)
                }}
              >
                <span>
                  <span className='font-medium'>
                    Usuario o contraseña incorrectos
                  </span>
                </span>
              </Alert>
            )}
          </form>
          <div className='absolute bottom-4'>
            <Footer.Copyright href='#' by='GrupOlivaMotor™' year={2022} />
          </div>
        </div>
      </section>
    </main>
  )
}

export default Login
