import { Button } from 'flowbite-react'
import { useAuth } from '../hooks/useAuth'

const LogoutButton = () => {
  const { logout } = useAuth()

  const handleClick = (event) => {
    event.preventDefault()
    logout()
  }

  return (
    <Button color='dark' size='xs' onClick={handleClick}>
      Salir
    </Button>
  )
}

export default LogoutButton
