import axios from 'axios'

const url = process.env.REACT_APP_HOST_API

const user = JSON.parse(localStorage.getItem('user'))

const getUsers = () => {
  const request = axios.get(url + '/publishing/api/v1/user', {
    headers: {
      Authorization: 'Bearer ' + user.token,
    },
  })
  return request.then((response) => response.data)
}

const addUser = (data) => {
  const request = axios.post(url + '/publishing/api/v1/user', data)
  return request.then((response) => response.data)
}

const updateUser = (id, data) => {
  const path = `/publishing/api/v1/user/${id}`

  console.log(data)
  const request = axios.put(url + path, data)
  return request.then((response) => response.data)
}

const deleteUser = (id) => {
  const path = `/publishing/api/v1/user/${id}`

  const request = axios.delete(url + path)
  return request.then((response) => response.data)
}

const UsersService = {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
}

export default UsersService
